import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export function openFullScreen(iframe: HTMLElement) {
  disableBodyScroll(iframe);
  iframe.classList.add("pulzio-fullscreen-iframe");
}

export function closeFullScreen(iframe: HTMLElement) {
  enableBodyScroll(iframe);
  iframe.classList.remove("pulzio-fullscreen-iframe");
}
