import { closeFullScreen, openFullScreen } from "./fullscreen";

function getSenderIframe(message: MessageEvent) {
  const iframes = Array.from(document.querySelectorAll("iframe"));
  return iframes.find((iframe) => iframe.contentWindow === message.source);
}

const STYLE_ID = "pulzio-embed-styles";

function setupClasses() {
  const existingStyle = document.getElementById(STYLE_ID);
  if (existingStyle) {
    return;
  }

  const style = document.createElement("style");
  style.innerHTML = `
  .pulzio-fullscreen-iframe {
    height: 100dvh !important;
    min-height: unset !important;
    max-height: unset !important;
    width: 100dvw !important;
    position: fixed !important;
    left: 0px !important;
    top: 0px !important;
    margin-top: 0px !important;
    z-index: 99999 !important;
  }
  `;
  style.id = STYLE_ID;
  document.getElementsByTagName("head")[0].appendChild(style);
}

setupClasses();

window.addEventListener(
  "message",
  (event: MessageEvent<{ type?: string } | undefined>) => {
    if (!event.data) {
      return;
    }

    if (!event.data.type) {
      return;
    }

    const type = event.data.type;

    if (!type.startsWith("pulzio:")) {
      return;
    }

    const iframe = getSenderIframe(event);

    if (!iframe) {
      console.warn("No iframe found for message", event);
      return;
    }

    if (type === "pulzio:openFullScreen") {
      openFullScreen(iframe);
    } else if (type === "pulzio:closeFullScreen") {
      closeFullScreen(iframe);
    }
  },
);
